import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '@env/environment';
import { Trackers } from '@loyalty-v3/libs';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TrackersWebService {
  readonly #http = inject(HttpClient);

  get(partnerId: string): Observable<Trackers> {
    return this.#http.get<Trackers>(
      `${environment.api.obendy}/v1/partner-revision/${partnerId}/trackers-configuration`
    );
  }

  put(partnerId: string, trackers: Trackers): Observable<void> {
    return this.#http.put<void>(
      `${environment.api.obendy}/v1/partner-revision/${partnerId}/trackers-configuration`,
      trackers
    );
  }
}
